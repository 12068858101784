<template>
    <template v-if="loaded == true">
            <!-- <p class="error" v-if="noFrontCamera">
                You don't seem to have a front camera on your device
            </p>

            <p class="error" v-if="noRearCamera">
                You don't seem to have a rear camera on your device
            </p> -->
            <!-- <div class="mb">
                Qr Stream
            </div> -->


            <div :class="{ 'fullscreen': fullscreen }" ref="wrapper" @fullscreenchange="onFullscreenChange">
                <qr-stream @decode="onDecode" @init="onInit" :camera="camera">
                    <button id="fullscreen" @click="fullscreen = !fullscreen" class="fullscreen-button">
                        <label v-if="fullscreen==false" for="fullscreen" uk-icon="icon:expand;ratio:2;"></label>
                        <label v-else for="fullscreen" uk-icon="icon:shrink;ratio:2;"></label>
                    </button>
                    <button id="switch_camera" @click="switchCamera" class="switch-button">
                        <label for="switch_camera" uk-icon="icon: refresh;ratio:2;">
                        </label>
                    </button>
                    <div style="color: red;" class="frame"></div>
                </qr-stream>
            </div>
            <!-- <qr-stream @decode="onDecode" :camera="camera" @init="onInit">
                    
                </qr-stream> -->

            <!-- <div class="result">
                Result: {{data}}
            </div> -->

    </template>
    <!-- <iframe src="../assets/sound/silent.mp3"
                allow="autoplay" id="audio" style="display:none"></iframe> -->
    <audio id="silent">
        <source src="../assets/sound/silent.mp3">
    </audio>
    <audio id="ok">
        <source src="../assets/sound/ok.mp3">
    </audio>
    <audio id="ng">
        <source src="../assets/sound/ng.mp3">
    </audio>
    <audio id="error">
        <source src="../assets/sound/error.mp3">
    </audio>
    <label for="refresh" v-if="loaded==false" uk-icon="icon: refresh; ratio: 2;">
        <button id="refresh" onclick="window.location.reload();" style="display: none;"></button>
    </label>
</template>

<script>
    /* eslint no-unused-vars: 0 */
    // import { reactive } from 'vue';
    import firebase from 'firebase/app'
    import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
    import swal2 from "sweetalert2"
    import { QrStream } from 'vue3-qr-reader'
    import { initializeApp } from "firebase/app";
    // import { getAnalytics } from "firebase/analytics";
    import { getFirestore } from "firebase/firestore";
    import { doc, getDoc, collection, addDoc, query, where, updateDoc, getDocs, getDocFromCache } from "firebase/firestore";
    // import { db } from "./firebase";
    // import { enablePersistence } from "firebase/firestore";
    import { enableMultiTabIndexedDbPersistence } from "firebase/firestore";
    import { CACHE_SIZE_UNLIMITED } from "firebase/firestore";
    import uikit from 'uikit';
    import { setDoc } from "firebase/firestore";
    import { disableNetwork } from "firebase/firestore";
    import { enableNetwork } from "firebase/firestore";
    import ok from "@/assets/sound/ok.mp3"
    import ng from "@/assets/sound/ng.mp3"
    import silent from "@/assets/sound/silent.mp3"
    import error from "@/assets/sound/error.mp3"


    // let audio = null

    // window.addEventListener('DOMContentLoaded', () => {
    //     // const sound = new Howl({
    //     //     src: ['../src/assets/sound/ok.mp3'],
    //     //     html5: true,
    //     //     onplayerror: function () {
    //     //         // 一番下の sound.play() で再生されなかった場合に実行されます。
    //     //         sound.once('unlock', function () {
    //     //             // touchendイベントが発生して音声の再生がロック解除されると実行されます。
    //     //             console.log('Play! [touchend]');
    //     //             sound.play();
    //     //         });
    //     //     },
    //     //     onunlock: function () {
    //     //         // touchendイベントが発生して音声の再生がロック解除されると実行されます。
    //     //         console.log('Play!');
    //     //         sound.play();
    //     //     }
    //     // });
    //     // sound.on("loaderror", () => {
    //     //     console.log("loaderror!"); // サウンドが読み込めないときに発生
    //     // })
    //     // sound.on("playerror", () => {
    //     //     console.log("playerror!"); // サウンドが再生できないときに発生
    //     // })
    //     // let startbtn = document.getElementById("startbtn")

    //     // startbtn.addEventListener("click", () => {
    //     //     console.log('Play!');
    //     //     sound.play();

    //     // })


    // });
    export default {
        data() {
            return {
                firebaseConfig: {},
                app: "",
                analytics: "",
                db: "",
                result: '',
                error: '',
                selected: "",
                options: "",
                loaded: false,
                data: "",
                sound: "",
                sound1: "",
                sound2: "",
                sound3: "",
                camera: "front",
                flg: false,
                email: "",
                password: "",
                noRearCamera: false,
                noFrontCamera: false,
                fullscreen: false,
            }
        },
        components: {
            QrStream,
        },
        mounted() {
            this.init()

        },
        created() {


        },
        watch: {
            fullscreen(enterFullscreen) {
                if (enterFullscreen) {
                    this.requestFullscreen()
                } else {
                    this.exitFullscreen()
                }
            }
        },
        computed: {
            fullscreenIcon() {
                if (this.fullscreen) {
                    return "/fullscreen-exit.svg"
                } else {
                    return "/fullscreen.svg"
                }
            }
        },
        methods: {
            init() {
                swal2.fire({
                    title: "メールアドレスとパスワードを入力してください",
                    confirmButtonText: 'Login',
                    backdrop: true,
                    allowOutsideClick: false,
                    html:
                        '<input id="input_email" type="email"  placeholder="Enter your email address" class="swal2-input">' +
                        '<input id="input_password" type="password" placeholder="Enter your password" class="swal2-input">',
                    didOpen: () => {
                        document.getElementById("input_email").value = sessionStorage.getItem("email_usr");
                        document.getElementById("input_password").value = sessionStorage.getItem("password_usr")
                    },
                    preConfirm: () => {
                        this.email = document.getElementById("input_email").value;
                        this.password = document.getElementById("input_password").value;
                        sessionStorage.setItem("email_usr", this.email)
                        sessionStorage.setItem("password_usr", this.password)
                    }

                }).then((result) => {
                    if (result.isConfirmed) {
                        this.firebaseConfig = {
                            apiKey: "AIzaSyDuWZ6n7Z9QVvxRJWLVDwZuA8C9UAgXt-Y",
                            authDomain: "non-contact-system.firebaseapp.com",
                            projectId: "non-contact-system",
                            storageBucket: "non-contact-system.appspot.com",
                            messagingSenderId: "988143458584",
                            appId: "1:988143458584:web:99cb51c9b5a2c12102266d",
                            measurementId: "G-QF7RYFCC0J"
                        }

                        // firebase.firestore()

                        this.app = initializeApp(this.firebaseConfig)
                        const auth = getAuth();
                        signInWithEmailAndPassword(auth, this.email, this.password)
                            .then((userCredential) => {
                                // Signed in
                                const user = userCredential.user;
                                console.log("user:" + user)
                                // this.analytics = getAnalytics(this.app)
                                this.db = getFirestore(this.app)
                                enableMultiTabIndexedDbPersistence(this.db).then((result) => {
                                })
                                    .catch((err) => {
                                        console.log("bb" + err)
                                        if (err.code == 'failed-precondition') {
                                            // Multiple tabs open, persistence can only be enabled
                                            // in one tab at a a time.
                                            // ...
                                        } else if (err.code == 'unimplemented') {
                                            // The current browser does not support all of the
                                            // features required to enable persistence
                                            // ...
                                        }
                                    });
                                // enableIndexedDbPersistence(this.db,{synchronizeTabs: true});
                                // this.db
                                console.log(this.db)
                                swal2.fire({
                                    title: 'このページでは音声が再生されますがよろしいですか？',
                                    showDenyButton: true,
                                    // showCancelButton: true,
                                    confirmButtonText: 'はい',
                                    denyButtonText: `いいえ`,
                                    backdrop: true,
                                    allowOutsideClick: false,
                                    didClose: () => {
                                        this.loaded = true
                                    }
                                }).then((result) => {
                                    /* Read more about isConfirmed, isDenied below */
                                    if (result.isConfirmed) {
                                        this.soundSilent()
                                    } else if (result.isDenied) {
                                        console.log("だめ")
                                    }
                                })
                                this.options = [
                                    { text: "nothing (default)", value: undefined },
                                    { text: "outline", value: this.paintOutline },
                                    { text: "centered text", value: this.paintCenterText },
                                    { text: "bounding box", value: this.paintBoundingBox },
                                ]
                                this.selected = this.options[1]
                                // document.addEventListener('touchstart', () => {
                                //     this.soundSilent()
                                // })
                                // ...
                            })
                            .catch((error) => {
                                const errorCode = error.code;
                                const errorMessage = error.message;
                                this.loaded = true;
                                console.log("cc" + error)
                            });
                    }
                })
            },
            onFullscreenChange(event) {
                // This becomes important when the user doesn't use the button to exit
                // fullscreen but hits ESC on desktop, pushes a physical back button on
                // mobile etc.

                this.fullscreen = document.fullscreenElement !== null
            },

            requestFullscreen() {
                const elem = this.$refs.wrapper
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) { /* Firefox */
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE/Edge */
                    elem.msRequestFullscreen();
                }
            },

            exitFullscreen() {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE/Edge */
                    document.msExitFullscreen();
                }
            },
            switchCamera() {
                switch (this.camera) {
                    case 'front':
                        this.camera = 'rear'
                        break
                    case 'rear':
                        this.camera = 'front'
                        break
                }
            },
            onDecode(data) {
                this.data = data
                this.post_qr(data)
            },
            soundSilent() {
                console.log("silent")
                this.sound = document.getElementById('silent');
                if (this.sound.readyState < 4) {
                    console.log('ロードされていないのでロードします。readyState：' + this.sound.readyState);
                    this.sound.load();
                }
                // 無音ファイルを再生
                this.sound.play();
                this.loop = true;
                // あとで再生したい音声ファイルを全てロード
                this.sound1 = document.getElementById('ok');
                this.sound1.load();
                this.sound2 = document.getElementById('ng');
                this.sound2.load();
                this.sound3 = document.getElementById('error');
                this.sound3.load();
                this.flg = true

            },
            // start_notification() {
            //     const sound = new Howl({
            //         src: [silent],
            //         html5: true,
            //         format: ["mp3"],
            //         autoUnlock: true,
            //         onend: function () {
            //             console.log('Finished!');
            //         }
            //     })
            //     console.log("sound")
            //     sound.play()
            // },
            ok_sound() {
                this.sound1.play()
                this.sound1.currentTime = 0
                // const sound = new Howl({
                //     src: [ok],
                //     html5: true,
                //     format: ["mp3"],
                //     volume: 1.0,
                //     autoUnlock: true,
                //     onend: function () {
                //         console.log('Finished!');
                //     }
                // })
                // console.log(sound)
                // sound.play()
            },
            ng_sound() {
                this.sound2.play()
                this.sound2.currentTime = 0
                // const sound = new Howl({
                //     src: [ng],
                //     html5: true,
                //     format: ["mp3"],
                //     volume: 1.0,
                //     autoUnlock: true,
                //     onload: () => {
                //         sound.play()
                //     },
                //     onend: function () {
                //         console.log('Finished!');
                //     }
                // })
                // sound.on("loaderror", () => {
                //     console.log("loaderror!"); // サウンドが読み込めないときに発生
                // })
                // sound.on("playerror", () => {
                //     console.log("playerror!"); // サウンドが再生できないときに発生
                // })
                // console.log(sound)
                // sound.play()
            },
            error_sound() {
                this.sound3.play()
                this.sound3.currentTime = 0
            },
            success_notification(last_name,first_name) {
                if (this.flg == true) {
                    this.ok_sound()
                }
                swal2.fire({
                    title: last_name+ "　"+ first_name + "  様",
                    html: "ご来場ありがとうございます。",
                    icon: "success",
                    timer: "5000"
                });

            },
            warning_notification() {
                if (this.flg == true) {
                    this.ok_sound()
                }
                swal2.fire({
                    title: "受付済みです",
                    html: "",
                    icon: "warning",
                    timer: "5000"
                });

            },
            online_notification() {
                if (this.flg == true) {
                    this.error_sound()
                }
                swal2.fire({
                    title: "オンライン視聴で申し込みされています",
                    html: "受付にご相談ください",
                    icon: "error",
                    timer: "5000"
                });

            },
            error_notification() {
                if (this.flg == true) {
                    this.ng_sound()
                }
                swal2.fire({
                    title: "不正なQRコードです",
                    html: "",
                    icon: "error",
                    timer: "5000"
                });

            },
            async onInit(promise) {
                try {
                    await promise
                } catch (error) {
                    const triedFrontCamera = this.camera === 'front'
                    const triedRearCamera = this.camera === 'rear'

                    const cameraMissingError = error.name === 'OverconstrainedError'

                    if (triedRearCamera && cameraMissingError) {
                        this.noRearCamera = true
                    }

                    if (triedFrontCamera && cameraMissingError) {
                        this.noFrontCamera = true
                    }

                    console.error(error)
                }
            },
            async post_qr(qr) {
                try {
                    swal2.fire({
                        icon: 'info',
                        title: '検索中',
                        backdrop:true,
                        allowOutsideClick: () => {return}
                    })
                        swal2.showLoading();
                        console.log(qr)
                        let now = new Date()
                        let year = now.getFullYear();
                        let month = (now.getMonth() + 1).toString().padStart(2, "0");
                        let date = now.getDate().toString().padStart(2, "0");
                        let h = now.getHours().toString().padStart(2, "0");
                        let m = now.getMinutes().toString().padStart(2, "0");
                        let s = now.getSeconds().toString().padStart(2, "0");
                        // const docRef = await addDoc(collection(this.db, "participant"), {
                        //     time_visited: year + "/" + month + "/" + date + " " + h + ":" + m + ":" + s,
                        //     uuid: qr
                        // });
                        // console.log(docRef)
                        // const querySnapshot = await getDocs(collection(this.db, "participant"));
                        // querySnapshot.forEach((doc) => {
                        //     console.log(`${doc.id} => ${doc.data()}`);
                        // });
                        const docRef = doc(this.db, "participant", qr);
                        const docSnap = await getDoc(docRef).catch(() => {
                            return getDocFromCache(docRef)
                        })
    
                        // if (docSnap.exists()) {
                        //     console.log("Document data:", docSnap.data());
                        // } else {
                        //     // doc.data() will be undefined in this case
                        //     console.log("No such document!");
                        // }
                        // let postDoc = await postRef.get() // firebase.firestore.DocumentSnapshotのインスタンスを取得
                        if (docSnap.exists()) {
                            console.log("Document data:", docSnap.data());
                            if (docSnap.get("online") == true) {
                                this.online_notification()
                            } else {
                                if (docSnap.get("visited_time") == null) {
                                    await updateDoc(docRef, {
                                        visited_time: year + "/" + month + "/" + date + " " + h + ":" + m + ":" + s
                                    }).then((result) => {
                                        console.log("success:" + result)
                                    }).catch((error) => {
                                        console.log("error:" + error)
                                    });
                                    this.success_notification(docSnap.get("last_name"),docSnap.get("first_name"))
                                    console.log("Document written with ID: ", docRef.id);
                                    this.$axios.post("/inquiry_qr", {
                                        UUID: qr,
                                        visited_time: year + "/" + month + "/" + date + " " + h + ":" + m + ":" + s
                                    }).then((response) => {
                                        console.log(response.data.flg)
                                    }).catch((error) => {
                                        console.log(error)
                                    })
                                } else {
                                    this.warning_notification()
                                }
                            }
                        } else {
                            console.log('No such document!')
                            swal2.hideLoading();
                            this.error_notification()
                        }
    
                        swal2.hideLoading();
                    
                } catch (e) {
                    console.error("Error adding document: " + e);
                    swal2.hideLoading();
                    this.error_notification()
                }

            },

        }
    }

</script>

<style scoped>
    .fullscreen {
        position: fixed;
        z-index: 1000;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }

    .fullscreen-button {
        background-color: white;
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 1rem;
    }
    .switch-button {
        background-color: white;
        position: absolute;
        top: 0;
        right: 0;
        margin: 1rem;
    }

    .fullscreen-button img {
        width: 2rem;
    }
</style>